import React from 'react';
import NavBar from './NavBar';

function Hotel() {

  return (
    <div className='hotel'>
      <NavBar />
      <div className='rounded-container'>
        <h3>Hotel</h3>
        <h4 align='center' className='readable'>
          <a href='https://www.marriott.com/events/start.mi?id=1619737246531&key=GRP'>
            <span>Marriot Courtyard</span>
          </a><br/>
          <a href='https://www.google.com/maps/dir//2701+main+street+irvine+ca+92614/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x80dcde844ce0f225:0xaa91f72501f740c4?sa=X&ved=2ahUKEwif7arvprrwAhXGvp4KHT8lAUYQ9RcwFXoECBsQBA'>
            <span>2701 Main Street <br/> Irvine, CA 92614</span>
          </a>
        </h4>
        <br/>
        <a href='https://www.marriott.com/events/start.mi?id=1619737246531&key=GRP'><button type='button' className='button'>Book Online Now</button></a>
        <br/><br/><br/>
        <p className="cursive">or call 1(800)321-2211 and book with <br/>the Wheeler Wedding Room Block</p>
        <p>Please make reservations no later than July 2, 2021 <br/>in order to receive the discounted room rate</p>
      </div>
    </div>
  )

}

export default Hotel
