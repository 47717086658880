import React from 'react';
import NavBar from './NavBar';

function Shuttle() {

  return (
    <div className='shuttle'>
      <NavBar />
      <div className='rounded-container'>
        <h3>Shuttle Info</h3>
        <h5 className='readable'> We provide shuttle transportation to & from our event venues and the Courtyard Marriot.</h5>
        <table align="center" className="shuttleInfo ">
          <tbody>
          <tr className="shuttleInfo">
            <h5 className='readable bold'>Puestos Los Olivos</h5>
            <h5 className='readable'>5:30 PM - 9:30 PM <br />every ~20-25 minutes</h5>
          </tr>
          <tr className="shuttleInfo">
            <h5 className='readable bold'>Eureka Building</h5>
            <h5 className='readable'>4:15 PM - 11:30 PM<br />every ~15-20 minutes</h5>
          </tr>
          <tr className="shuttleInfo">
            <h5 className="readable bold">Crystal Cove</h5>
            <h5 className="readable">let us know if you will join us and <br />we'll provide you transportation info.</h5>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  )

}

export default Shuttle
