import React from 'react';

class PartyMember extends React.Component {

  render() {
    return(
      <>
        <h3 className="party">{this.props.guest.fname} {this.props.guest.lname}</h3>
        <p><label>Attending: </label> {this.props.guest.attending ? "Yes":"No"} | <label>Hotel: </label>{this.props.guest.hotel ? "Yes":"No"} | <label>Shuttle: </label>{this.props.guest.shuttle ? "Yes":"No"}</p>
        <p><label>Notes: </label> <input type="textarea" className="text" value={this.props.guest.notes} readOnly={true}/></p>
      </>
    )
  }
}

export default PartyMember
