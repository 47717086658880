import React from 'react';
import NavBar from './NavBar';

class Registry extends React.Component {

  render() {
    return(
      <div className="rsvp">
        <NavBar />
        <div className="rounded-container">
          <h3>Registry</h3>
          <p className='italicized'>If you wish to give our family a gift, < br/> we kindly request no boxed gifts. <br/><br/></p>
          { /* <h4 className='readable'> coming soon </h4> */ }
          <a href='https://paypal.me/pools/c/8zSb8AGhw8'><button type='button' className='button'> Give Online </button></a>
        </div>
      </div>
    )
  }
}

export default Registry
