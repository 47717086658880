import React from 'react';
import NavBar from './NavBar';

class Address extends React.Component {

  constructor() {
    super();
    this.state = {
      emailAddress: "",
      'entry.1698355344': "",
      'entry.959469146': "",
      'entry.1418003675': "",
      'entry.1647937196': "",
      'entry.524943294': "",
      'entry.854498871': ""
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  render() {
    return (
      <div className="address">
        <NavBar />
        <div className='guest-address rounded-container'>
          <h3>mailing address form</h3>

          <form action="https://docs.google.com/forms/d/e/1FAIpQLSe_pwY0_bv-5J5FaAxV_lODHRmldCxilZQxn44BQak65WHa9A/formResponse" target="_self" method="POST" id="mG61Hd" >

            <input type="text" onChange={this.handleChange} value={this.state.['entry.1698355344']} placeholder="Name(s)" aria-label="Name" aria-describedby="i.desc.295288688 i.err.295288688" name="entry.1698355344" dir="auto" data-initial-dir="auto" data-initial-value="" />
            <br/>
            <input type="email" onChange={this.handleChange} value={this.state.emailAddress} placeholder="E-mail Address" aria-label="Your email" name="emailAddress" required="" dir="auto" data-initial-dir="auto" data-initial-value="" aria-invalid="true" />
            <br/><br/>
            <input type="text" onChange={this.handleChange} value={this.state.['entry.959469146']} placeholder="Street Address" aria-label="Street Address" aria-describedby="i.desc.55927553 i.err.55927553" name="entry.959469146" dir="auto" data-initial-dir="auto" data-initial-value="" />
            <br/>
            <input type="text" onChange={this.handleChange} value={this.state.['entry.1418003675']} placeholder="Apt #" aria-label="Apartment #" aria-describedby="i.desc.1628062402 i.err.1628062402" name="entry.1418003675" dir="auto" data-initial-dir="auto" data-initial-value="" />
            <br/><br/>
            <input type="text" onChange={this.handleChange} value={this.state.['entry.1647937196']} placeholder="City" aria-label="City" aria-describedby="i.desc.2101454813 i.err.2101454813" name="entry.1647937196" dir="auto" data-initial-dir="auto" data-initial-value="" />
            <br/>
            <input type="text" onChange={this.handleChange} value={this.state.['entry.524943294']} placeholder="State" aria-label="State" aria-describedby="i.desc.601238745 i.err.601238745" name="entry.524943294" dir="auto" data-initial-dir="auto" data-initial-value="" />
            <br/>
            <input type="text" onChange={this.handleChange} value={this.state.['entry.854498871']} placeholder="Zip Code" aria-label="Zip Code" aria-describedby="i.desc.930553772 i.err.930553772" name="entry.854498871" dir="auto" data-initial-dir="auto" data-initial-value="" />
            <br/><br/>
            <input type="submit" value="submit" className='button'/>

            <input type="hidden" name="fvv" value="1" />
            <input type="hidden" name="draftResponse" value="[null,null,&quot;-7437607041144068817&quot;]" />
            <input type="hidden" name="pageHistory" value="0" />
            <input type="hidden" name="fbzx" value="-7437607041144068817" />

          </form>

        </div>
      </div>
    )
  }

}

export default Address
