import React from 'react';
import NavBar from './NavBar';
import phanwheeler from '../media/phanwheeler.ics';
import { Link } from "react-router-dom";

function Events() {

  return (
    <div className="events">
      <NavBar />
      <div className='rounded-container'>
        <div className="lds-heart">
          <div>
          </div>
        </div>
      <h3>Celebration Weekend</h3>
        <center><table>
          <tbody>
            <tr>
              <td className='eventsDateCol'>
                <h5 align='center' className='readable'>Friday</h5>
                <h4 align='center' className='readable'>JULY 23</h4>
                <h5 align='center' className='readable'> 6:00 PM </h5>
                <p align='center' className='italicized'>casual attire</p>
              </td>
              <td>
                <p className='readable bold'>Out-of-Town Dinner @ Puesto Los Olivos</p>
                <p className='italicized'>Graciously hosted by Brandon’s Aunt Nita, Uncle Allan, and Grandma Beryl</p>
                <h5 className='readable'> <Link to='/oot'>by invitation</Link> ||
                <Link to='/shuttle'> shuttle information</Link></h5>

            </td>
            </tr>

            <tr>
              <td>
                <h5 align='center' className='readable'>Saturday</h5>
                <h4 align='center' className='readable'>JULY 24</h4>
                <h5 align='center' className='readable'> 4:30 & 5:45 PM </h5>
                <a className="addToCal" href={phanwheeler} download>
                  <p align='center' className="readable"><img width="15px" src="https://www.addevent.com/gfx/icon-calendar-t5.png" alt="calendar icon"/> Add to Cal</p>
                </a>
                <p align='center' className='italicized'>cocktail attire</p>
              </td>
              <td>
                <p className='readable bold'>Welcome & Reception</p>
                <p className='readable'><a href="https://www.google.com/maps/place/Eureka+Building/@33.699239,-117.849146,17z/data=!3m2!4b1!5s0x80dce63b03be9371:0x13660fdad9ec3e62!4m5!3m4!1s0x80dcdebb968f2c9b:0xcb4f2344f0fdae17!8m2!3d33.699239!4d-117.8469573">Eureka Building ~ 1621 Alton Pkwy, Irvine, CA 92606</a></p>
                <ul className='readable'>
                  <li>Ceremony 4:30 PM</li>
                  <li>Reception 5:45 PM</li>
                </ul>
                <h5 className='readable'><Link to='rsvp'>rsvp</Link> ||<Link to='/shuttle'> shuttle information</Link></h5>
              </td>
            </tr>

            <tr>
              <td>
                <h5 align='center' className='readable'>Sunday</h5>
                <h4 align='center' className='readable'>JULY 25</h4>
                <h5 align='center' className='readable'> 10 AM </h5>
                <p align='center' className='italicized'>casual attire</p>
              </td>
              <td>
                <p className='readable bold'>Bagels & Breezes</p>
                <p className='readable italicized'>Grab a breakfast bag from the Courtyard Marriot as early 8 AM if you need to head out! For those staying a while longer, feel free to grab a breakfast bag and join Becky & Brandon at Crystal Cove at ~ 10 AM.</p>
                <h5 className='readable'>Transportation can be provided to & from Courtyard Marriot/Crystal Cove - please contact Brandon/Becky to arrange.</h5>
              </td>
            </tr>
          </tbody>
        </table></center>
        <br/>
      </div>
      <br />
      <br />
    </div>
  )

}

export default Events
