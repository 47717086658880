import './App.css';
import React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './components/Home';
import Rsvp from './components/Rsvp';
import Address from './components/Address';
import Story from './components/Story';
import Hotel from './components/Hotel';
import Events from './components/Events';
import Footer from './components/Footer';
import AdminView from './components/AdminView';
import Registry from './components/Registry';
import Dinner from './components/Dinner';
import Shuttle from './components/Shuttle';
import Announcements from './components/Announcements';
import Photos from './components/Photos';

class App extends React.Component {

  render() {
    const heroku = process.env.REACT_APP_DATABASEURL
    return (
      <Router>
        <div className="App">
          <Switch>
            <Route exact path='/address' component={Address}/>
            <Route exact path='/announcements' component={Announcements}/>
            <Route exact path='/story' component={Story}/>
            <Route exact path='/accomodations' component={Hotel}/>
            <Route exact path='/events' component={Events}/>
            <Route exact path='/oot' component={Dinner}/>
            <Route exact path='/shuttle' component={Shuttle}/>
            <Route exact path='/photos' component={Photos}/>
            <Route exact path='/registry' component={Registry}/>
            <Route exact path="/rsvp" render={(props) => (
                <Rsvp {...props} dbUrl={heroku} />
              )}
            />
          <Route exact path="/admin/bpw-rsvp-list" render={(props) => (
                <AdminView {...props} dbUrl={heroku} />
              )}
            />
            <Route exact path='/' component={Home} />
          </Switch>
          <Footer />
        </div>
      </Router>
    );
  }

}

export default App;
