import React from 'react';

class CreateParty extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      hoh: ""
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.resetForm !== prevProps.resetForm) {
      this.setState({
        name: "",
        hoh: ""
      })
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  render() {
    return (
      <div className='rounded-container'>
      <h3 className="party">Add Party</h3>
        <form onSubmit={this.props.handleSubmit} >
          <input type="text" name="name" value={this.state.name} onChange={this.handleChange} placeholder="name" /><br />
          <input type="text" name="hoh" value={this.state.hoh} onChange={this.handleChange} placeholder="head of household" /><br />
          <br /><br />
          <input type="submit" value="Submit" className="button" />
        </form>
      </div>
    )
  }
}

export default CreateParty
