import React from 'react';
import NavBar from './NavBar';

function Announcements() {

  return (
    <div className="announcements">
      <NavBar />
      <div className='rounded-container'>
        <div className="inner-container">
          <h3>Event Details</h3>
          <table>
            <tbody>
              <tr className="shuttleInfo"><h4 className="readable" id="weather">Weather, Safety & Attire</h4>
                <p className='readable'>High 77°F | Low 68°F
                <br />We have moved our event entirely outdoors, so dress comfortably!
                It's expected that all our guests (except for children) are vaccinated,
                but feel free to don your masks whenever you want!</p></tr>
              <tr className="shuttleInfo"><h4 className="readable" id="venue">Venue & Parking</h4>
                <p className='readable'>Eureka Building
                1621 Alton Pkwy
                Irvine, CA 92606
                There is ample parking in the Eureka Building lot, so drive on in.
                The entrance to the outdoor venue is back towards the end of the building.</p></tr>
              <tr className="shuttleInfo">  <h4 className="readable" id="schedule">Saturday Event Schedule</h4>
              <div>
                <table className="mini">
                  <tbody>
                    <tr className="shuttleInfo">
                      <td align="left">4:30 PM</td>
                      <td align="left">Ceremony, <br />Cocktail Hour to Follow</td>
                    </tr>
                    <tr className="shuttleInfo">
                      <td align="left">5:45 - <br />7:00 PM</td>
                      <td  align="left">Dinner Reception</td>
                    </tr>
                    <tr className="shuttleInfo">
                      <td align="left">7:00 - <br />11:00 PM</td>
                      <td align="left">Merriment, Music, <br />More Drinks, & Cake!</td>
                    </tr>
                  </tbody>
                </table>
                <br />
              </div>
            </tr>
              <tr className="shuttleInfo"><h4 className="readable" id="dinner">Dinner Reception & Seating</h4>
              <p className='readable'>There will be a family-style dinner of meat and seafood at every table.
                If you have opted for a veg meal, servers should be bringing out your dishes.
                Pescatarians who marked vegetarian--feel free to check out what's coming out to your table!
                We just ask that everyone leaves room for a slice of cake (or two)!
<br /><br />
                If you want more room to dine, get food from your assigned table
                then wander over to an open table/cocktail table to eat.</p></tr>
              <tr className="shuttleInfo"><h4 className="readable" id="drinks">Open Bar</h4>
              <p className='readable'>Drinks on drinks on drinks on us! <br/>Feel free to tip our bartenders!
                <br />Please drink responsibly</p></tr>
              <tr className="shuttleInfo"><h4 className="readable" id="shuttle">Shuttle Info</h4>
              <p className='readable'>For those staying at or near the Courtyard Marriott,
                we have shuttles making loops
                between the Courtyard Marriott Irvine & Eureka Building
                every 15-20 minutes between 4:15 PM and 11:30 PM. </p></tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )

}

export default Announcements
