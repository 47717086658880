import React from 'react';
import { NavLink } from "react-router-dom";

function NavBar() {
  return(
    <nav className="nav">
      <NavLink exact activeClassName="active" to="/" className='navbar-items link'><span>home.</span></NavLink>
      <NavLink exact activeClassName="active" to="https://www.postable.com/phanwheeler" className='navbar-items link'><span>snail mail.</span></NavLink>
      {/* <NavLink exact activeClassName="active" to="/rsvp" className='navbar-items link'><span>RSVP</span></NavLink> */}
      {/* <NavLink exact activeClassName="active" to="/events" className='navbar-items link'><span>events</span></NavLink> */}
      {/* <NavLink exact activeClassName="active" to="/address" className='navbar-items link'><span>mail</span></NavLink>*/}
      {/* <NavLink exact activeClassName="active" to="/story" className='navbar-items link'><span>story</span></NavLink> */}
      {/* <NavLink exact activeClassName="active" to="/accomodations" className='navbar-items link'><span>hotel</span></NavLink>*/}
      {/* <NavLink exact activeClassName="active" to="/shuttle" className='navbar-items link'><span>shuttle</span></NavLink> */}
      { /* <NavLink exact activeClassName="active" to="/hayden" className='navbar-items link'><span>lovebug</span></NavLink> */ }
      { /* <NavLink exact activeClassName="active" to="/announcements" className='navbar-items link'><span>announcements</span></NavLink> */ }
      { /* <NavLink exact activeClassName="active" to="/thankyou" className='navbar-items link'><span>thank you</span></NavLink> */ }
      {/* <NavLink exact activeClassName="active" to="/photos" className='navbar-items link'><span>photos</span></NavLink> */}
      { /* <NavLink exact activeClassName="active" to="/registry" className='navbar-items link'><span>registry</span></NavLink> */}
    </nav>
  )
}

export default NavBar
