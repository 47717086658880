import React from 'react';
import axios from 'axios';
import CreateGuest from './CreateGuest';
import PartyMember from './PartyMember';

class FetchGuestsByParty extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      guests: [],
      edit: false,
      editCount: 0,
      resetToggle: false
    }
  }

  componentDidMount() {
    axios.get(this.props.dbUrl + 'parties/' + this.props.partyId)
    .then(resp => {
      this.setState({
        guests: resp.data.data
      })
    })
    .catch(error => console.log(error))
  }

  handleGuestSubmit = (event) => {
    event.preventDefault()

    let formData = {
      fname: event.target[0].value,
      lname: event.target[1].value,
      plusones: event.target[2].value,
      party_id: event.target[3].value
    }

    axios.post(this.props.dbUrl + 'admin/guests', formData)
    .then(resp => {
      this.setState({
        guests: this.state.guests.concat(resp.data.data),
        resetToggle: !this.state.resetToggle
      })
    })
    .catch(error => {
      alert(error)
      this.setState({
        resetToggle: !this.state.resetToggle
      })
    })
  }

  handleEdit = (event) => {
    if (this.state.editCount === 0) {
      this.setState({
        name: "",
        hoh: "",
        edit: true
      })
    } else {
      this.setState({
        edit: true
      })
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handlePartySubmit = (event) => {
    event.preventDefault()

    let formData = {
      name: this.state.name,
      hoh: this.state.hoh
    }

    axios.put(this.props.dbUrl + 'admin/parties/' + this.props.partyId, formData)
    .then(resp => {
      this.setState((prevState) => ({
        edit: false,
        editCount: prevState.editCount + 1,
        name: resp.data.data.attributes.name,
        hoh: resp.data.data.attributes.hoh
      }))
    })
    .catch(error => {
      alert(error)
      this.setState({
        edit: false
      })
    })
  }

  handleDelete = (event) => {
    let r = window.confirm(`Are you sure you want to delete ${event.currentTarget.lastChild.data}?`)

    if (!!r) {
      axios.delete(this.props.dbUrl + 'admin/guests/' + event.target.id)
      .then(resp => {
        this.setState({
          guests: this.state.guests.filter(guest => guest.id !== event.target.id)
        })
      })
      .catch(function (error) {
        alert(error)
      })
    }
  }

  render() {
    let guestsInfo;
    if (this.state.guests.length > 0) {
      guestsInfo = this.state.guests.map(guest => <><PartyMember key={guest.id} id={guest.id} guest={guest.attributes} resetForm={this.state.resetToggle} /><button className="deleteGuest" id={guest.id} onClick={this.handleDelete}>delete {guest.attributes.fname}</button></>)
    }

    let partyInfo;
    if (this.state.edit && this.state.editCount === 0) {
      partyInfo= <form onSubmit={this.handlePartySubmit} >
                  <input type="text" name="hoh" placeholder={this.props.hoh} onChange={this.handleChange} />
                  <input type="text" name="name" placeholder={this.props.name} onChange={this.handleChange} />
                  <input type="submit" value="submit" />
                </form>
    } else if (this.state.edit && this.state.editCount > 0) {
      partyInfo= <form onSubmit={this.handlePartySubmit} >
                  <input type="text" name="hoh" placeholder={this.state.hoh} onChange={this.handleChange} />
                  <input type="text" name="name" placeholder={this.state.name} onChange={this.handleChange} />
                  <input type="submit" value="submit" />
                </form>
    } else if (this.state.editCount === 0) {
      partyInfo = <h3 className="party" id={this.props.partyId} onClick={this.handleEdit}>{this.props.hoh} {this.props.name}</h3>
    } else {
      partyInfo = <h3 className="party" id={this.props.partyId} onClick={this.handleEdit}>{this.state.hoh} {this.state.name}</h3>

    }

    return (
      <div className="rounded-container">
        <div className="button-parent"><button className="delete" id={this.props.partyId} onClick={this.props.handleDelete}>X</button></div><br/>
        {partyInfo}
        {guestsInfo}
        <CreateGuest dbUrl={this.props.dbUrl} partyId={this.props.partyId} handleSubmit={this.handleGuestSubmit} resetForm={this.state.resetToggle}/>
      </div>
    )
  }
}

export default FetchGuestsByParty
