import React from 'react';
import axios from 'axios';
import NavBar from './NavBar';
import CreateParty from './CreateParty';
import FetchGuestsByParty from './FetchGuestsByParty';

class AdminView extends React.Component {

  constructor() {
    super();

    this.state = {
      parties: [],
      resetToggle: false
    }
  }

  componentDidMount() {
    axios.get(this.props.dbUrl + 'parties')
    .then(resp => {
      this.setState({
        parties: resp.data.data
      })
    })
    .catch(error => console.log(error))
  }

  handleSubmit = (event) => {
    event.preventDefault()

    let formData = {
        [event.target[0].name]: event.target[0].value,
        [event.target[1].name]: event.target[1].value,
      }

    axios.post(this.props.dbUrl + 'admin/parties', formData)
    .then(resp => {
      if (resp.data.data.id) {
        this.setState({
          parties: this.state.parties.concat(resp.data.data),
          resetToggle: !this.state.resetToggle
        })
        alert('Party Successfuly Added!')
      } else {
        this.setState({
          resetToggle: !this.state.resetToggle
        })
        alert(`Sorry, ${event.target[0].value} could not be added. Please try again.`)
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  handleDelete = (event) => {
    let r = window.confirm(`Are you sure you want to delete ${event.target.parentNode.parentNode.children[2].innerText}?`)

    if (!!r) {
      axios.delete(this.props.dbUrl + 'admin/parties/' + event.target.id)
      .then(resp => {
        this.setState({
          parties: this.state.parties.filter(party => party.id !== event.target.id)
        })
      })
      .catch(function (error) {
        console.log(error)
      })
    }

  }

  render() {
    let parties;

    if (this.state.parties.length > 0) {
      let sorted = this.state.parties.slice()
      sorted.sort((a, b) => a.attributes.name.toLowerCase() > b.attributes.name.toLowerCase() ? 1:-1)
      parties = sorted.map(party => <FetchGuestsByParty key={party.id} partyId={party.id} dbUrl={this.props.dbUrl} name={party.attributes.name} hoh={party.attributes.hoh} handleDelete={this.handleDelete}/>)
    }

    return (
      <>
        <NavBar />
        <CreateParty handleSubmit={this.handleSubmit} resetForm={this.state.resetToggle} />
        {parties}
      </>
    )
  }
}

export default AdminView
