import React from 'react';
import breckyfam from '../media/pwfamily.JPG';
import NavBar from './NavBar';

class Home extends React.Component {

  render() {
    return(
      <div className='main'>
        <NavBar />
        <div className='save-the-date'>
            <div>
              <img class="hover" alt="phan-wheeler family by idriss njike" src={breckyfam}/>
            </div>
            <br/>
            <br/>
        </div>
    </div>
    )
  }
}

export default Home
