import React from 'react';
import NavBar from './NavBar';

class Dinner extends React.Component {

  render() {
    return(
      <div className="dinner">
        <NavBar />
        <div className="rounded-container">
          <h3>Out-of-Town Dinner <br /> @ Puesto Los Olivos</h3>
          <h4 className='readable'>Friday, July 23rd, 2021 @ 6PM</h4>
          <div>
            <iframe title="RSVP form" src="https://phanwheelerootdinner.rsvpify.com" frameborder="0">Loading...</iframe>
          </div>
        </div>
      </div>
      )
    }
  }

export default Dinner
