import React from 'react';
import NavBar from './NavBar';

function Photos() {

  return (
    <div className='photos'>
      <NavBar />
      <div className='rounded-container'>
        <h3>Photos</h3>
        <script src='https://embedsocial.com/js/iframe.js'></script>
        <iframe title="phanwheeler" frameBorder="0" width="100%" src='https://embedsocial.com/facebook_album/pro_hashtag/9355da0795f27a7625b58cf831e937cafb4ea523'></iframe>
      </div>
    </div>
  )

}

export default Photos
