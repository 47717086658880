import React from 'react';

class CreateGuest extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      lname: "",
      plusones: 0
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.resetForm !== prevProps.resetForm) {
      this.setState({
        fname: "",
        lname: "",
        plusones: 0
      })
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  render() {
    return (
      <div className='rounded-container'>
      <label>Add Guest</label>
        <form onSubmit={this.props.handleSubmit} >
          <input type="text" name="fname" value={this.state.fname} onChange={this.handleChange} placeholder="first name" /><br />
          <input type="text" name="lname" value={this.state.lname} onChange={this.handleChange} placeholder="last name" /><br />
          <label>Personal Plus Ones: <input type="number" name="plusones" value={this.state.plusones} onChange={this.handleChange} placeholder="plusones" /></label>
          <input type="hidden" name="party_id" value={this.props.partyId} />
          <br /><br />
          <input type="submit" value="Submit" className="button" />
        </form>
      </div>
    )
  }
}

export default CreateGuest
