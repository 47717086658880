import React from 'react';
import NavBar from './NavBar';

class Rsvp extends React.Component {

  render() {
    return(
      <div className="rsvp">
        <NavBar />
        <div className="rounded-container">
          <h3>répondez s'il vous plaît</h3>
          <h2>SATURDAY, JULY 24, 2021 | IRVINE, CALIFORNIA</h2>
          <h4 className="readable"> please contact becky & brandon to RSVP</h4>
          { /* <h4 className='readable'> coming soon </h4> 
          <div className="embedded-form">
            <iframe title="RSVP form" src="https://docs.google.com/forms/d/e/1FAIpQLScXpUUk3TVNjaTxoaUyyG2R_jC9muwbr0B4ZIggIOePjRzwyw/viewform?embedded=true" width="350" frameborder="0" scrolling="no">Loading…</iframe>
          </div>
          */ }
        </div>
      </div>
    )
  }
}

export default Rsvp
