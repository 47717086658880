import React from 'react';
import NavBar from './NavBar';
import brecky1 from '../media/brecky-1.png';
import brecky2 from '../media/brecky-2.png';
import brecky3 from '../media/brecky-3.png';
import brecky4 from '../media/brecky-4.png';
import brecky5 from '../media/brecky-5.png';

function Story() {

  return (
    <div className="story">
      <NavBar />
      <div className='rounded-container'>
      <div className='photo-container'>
        <span><img alt="becky and brandon" src={brecky1} height='100' /></span>
        <span><img alt="becky and brandon" src={brecky2} height='100' className="rotateimg80" /></span>
        <span><img alt="becky and brandon" src={brecky3} height='100' className="rotateimg15" /></span>
        <span><img alt="becky and brandon" src={brecky4} height='100' /></span>
        <span><img alt="becky and brandon" src={brecky5} height='100' className="rotateimg-15" /></span>
      </div>
        <h3>our meet-cute</h3>
        <br/>
        <div>
          <p className='cursive'>
            &#8195; &#8195; It all begins with an invitation to a 'surprise soiree' on May 12th, 2017.
            That night, Becky enjoyed the company of friends what she believed to be a birthday party (it was not) and met 'the new roommate' aka Brandon. <br/><br/>
          It was a "whirlwind romance" <br/>and "love at first sight", <br/>and soon they were dating, <br/>then married, <br/>then engaged, <br/>then expecting,
            <br/>then married again, <br/>and then they welcomed sweet baby Hayden. <br/><br/>Now, after surviving a pandemic, <br/>they're ready to re-enter society <br/> and celebrate their love <br/>and their new little family with all of you.<br/><br/>
          </p>
          <h3> thank you for celebrating with us. </h3>
            <br/><br/>
        </div>
      </div>
    </div>
  )

}

export default Story
